import { FetchTasksSortTypes } from './tasksTypes';

import { statusesKeys } from '../../locales/keys/models/statuses';

export const INITIAL_TASKS_FILTERS = {};
export const INITIAL_TASKS_PAGE = 1;
export const INITIAL_TASKS_SORT = [FetchTasksSortTypes.CREATED_AT_DESC];
export const INITIAL_TASKS_LIMIT = 50;

export const tasksStatuses = [
  { value: 'definition', i18nLabel: statusesKeys.types.definition },
  {
    value: 'implementation',
    i18nLabel: statusesKeys.types.implementation
  },
  { value: 'qa', i18nLabel: statusesKeys.types.qa },
  {
    value: 'incomplete_specification',
    i18nLabel: statusesKeys.types.incompleteSpecification
  },
  { value: 'acceptance', i18nLabel: statusesKeys.types.acceptance },
  { value: 'paused', i18nLabel: statusesKeys.types.paused },
  { value: 'performed', i18nLabel: statusesKeys.types.performed },
  { value: 'canceled', i18nLabel: statusesKeys.types.canceled }
];

export const enum TaskTabs {
  BRIEF = 'brief',
  MESSAGES = 'messages',
  ATTACHMENTS = 'attachments',
  RESULTS = 'results',
  TEAM = 'team',
  NOTIFICATIONS = 'notifications',
  TRANSACTIONS = 'transactions',
  ACTIVITY = 'activity'
}

export const enum TasksPermissions {
  READ_TASKS_INDEX_PAGE = 'read_tasks_index_page',
  READ_TASK_BRIEF_PAGE = 'read_task_brief_page',
  READ_TASK_MESSAGES_PAGE = 'read_task_messages_page',
  READ_TASK_ATTACHMENTS_PAGE = 'read_task_attachments_page',
  READ_TASK_FILES_PAGE = 'read_task_files_page',
  READ_TASK_IMAGES_PAGE = 'read_task_images_page',
  READ_TASK_SOURCE_FILES_PAGE = 'read_task_source_files_page',
  READ_TASK_PRODUCTS_PAGE = 'read_task_products_page',
  READ_TASK_LIFESTYLES_PAGE = 'read_task_lifestyles_page',
  READ_TASK_MATERIALS_PAGE = 'read_task_materials_page',
  READ_TASK_RESULTS_PAGE = 'read_task_results_page',
  READ_TASK_TEAM_PAGE = 'read_task_team_page',
  READ_TASK_NOTIFICATIONS_PAGE = 'read_task_notifications_page',
  READ_TASK_TRANSACTIONS_PAGE = 'read_task_transactions_page',
  READ_TASK_ACCEPT_PAGE = 'read_task_accept_page',
  READ_TASK_ACTIVITY_PAGE = 'read_task_activity_page',
  READ_TASKS_RESULTS_PAGE = 'read_tasks_results_page',
  READ_TASK_SEND_INVOICE_BUTTON = 'read_task_send_invoice_button',
  READ_TASK_SUBMIT_RESULTS_BUTTON = 'read_task_submit_results_button',
  READ_ACCEPT_TASK_BUTTON = 'read_accept_task_button',
  READ_CANCEL_TASK_BUTTON = 'read_cancel_task_button',
  READ_PAUSE_TASK_BUTTON = 'read_pause_task_button',
  READ_INCOMPLETE_SPECIFICATION_TASK_BUTTON = 'read_incomplete_specification_task_button',
  READ_REVISION_TASK_BUTTON = 'read_revision_task_button',
  READ_REVISION_QA_TASK_BUTTON = 'read_revision_qa_task_button',
  READ_RESTORE_TASK_BUTTON = 'read_restore_task_button',
  READ_ACCEPT_RESULTS_IN_TASK_BUTTON = 'read_accept_results_in_task_button',
  READ_DOWNLOAD_TASK_RESULTS_BUTTON = 'read_download_task_results_button',
  READ_APPROVE_RESULTS_IN_TASK_BUTTON = 'read_approve_results_in_task_button',
  READ_TASK_SUBMIT_SOURCE_FILES_BUTTON = 'read_task_submit_source_files_button',
  READ_TASK_LEAVE_BUTTON = 'read_task_leave_button',
  READ_TASKS_SAVED_FILTERS_MENU = 'read_tasks_saved_filters_menu',
  CHANGE_TASK_SUPPORT_MARK = 'change_task_support_mark',
  CHANGE_TASK_MARK = 'change_task_mark',
  CHANGE_TASK_RED = 'change_task_red',
  CHANGE_TASK_FAVORITE = 'change_task_favorite',
  CHANGE_TASK_VISIBLE_FOR_CLIENT = 'change_task_visible_for_client',
  READ_TASK_TRACK_TIME_BUTTON = 'read_task_track_time_button',
  READ_TASK_TAGS = 'read_task_tags',
  READ_TASKS_RANKED_NAME_FILTER = 'read_tasks_ranked_name_filter',
  READ_TASKS_BURNING_FILTER_MENU = 'read_tasks_burning_filter_menu',
  READ_TASKS_INVITATIONS_FILTER_MENU = 'read_tasks_invitations_filter_menu',
  READ_TASKS_FAVORITE_FILTER_MENU = 'read_tasks_favorite_filter_menu',
  READ_TASKS_MY_TASKS_FILTER_MENU = 'read_tasks_my_tasks_filter_menu',
  READ_TASKS_RESULTS_MENU = 'read_tasks_results_menu',
  READ_TASKS_TABLE_LAST_ACTIVITY_FIELD = 'read_tasks_table_last_activity_field',
  READ_TASKS_TABLE_UUID_FIELD = 'read_tasks_table_uuid_field',
  READ_TASKS_TABLE_FAVORITE_FIELD = 'read_tasks_table_favorite_field',
  READ_TASKS_TABLE_CHECKBOXES = 'read_tasks_table_checkboxes',
  READ_TASKS_TABLE_BURNING_FIELD = 'read_tasks_table_burning_field',
  READ_TASKS_TABLE_USER_FIELD = 'read_tasks_table_user_field',
  READ_TASKS_TABLE_TEAM_FIELD = 'read_tasks_table_team_field',
  READ_TASKS_TABLE_IMPLEMENTATION_DATE_FIELD = 'read_tasks_table_implementation_date_field',
  READ_TASKS_TABLE_STATUS_FIELD = 'read_tasks_table_status_field',
  READ_TASKS_TABLE_AMOUNT_FIELD = 'read_tasks_table_amount_field',
  READ_TASKS_TABLE_CLIENT_PREPAYMENT_FIELD = 'read_tasks_table_client_prepayment_field',
  READ_TASKS_TABLE_ID_FIELD = 'read_tasks_table_id_field',
  READ_TASKS_TABLE_CREATED_AT_FIELD = 'read_tasks_table_created_at_field',
  READ_TASKS_TABLE_NAME_FIELD = 'read_tasks_table_name_field',
  READ_TASKS_TABLE_DESCRIPTION_FIELD = 'read_tasks_table_description_field',
  READ_TASKS_TABLE_LAST_MESSAGE_FIELD = 'read_tasks_table_last_message_field',
  READ_TASKS_TABLE_PROJECT_NAME_FIELD = 'read_tasks_table_project_name_field',
  READ_TASKS_TABLE_PROJECT_LINK_FIELD = 'read_tasks_table_project_link_field',
  READ_TASKS_TABLE_SOURCE_FILES_BUTTON = 'read_tasks_table_source_files_button',
  CHANGE_TASK_DESCRIPTION = 'change_task_description',
  CHANGE_TASK_REVISION_IMPLEMENTATION_DATE_FILED = 'change_task_revision_implementation_date_field',
  CHANGE_TASK_AMOUNT = 'change_task_amount',
  CHANGE_TASK_IMPLEMENTATION_DATE = 'change_task_implementation_date',
  CHANGE_TASK_IMPLEMENTATION_TIME = 'change_task_implementation_time',
  CHANGE_TASK_WIDTH = 'change_task_width',
  CHANGE_TASK_HEIGHT = 'change_task_height',
  CHANGE_TASK_DPI = 'change_task_dpi',
  CHANGE_TASK_FILE_EXTENSION = 'change_task_file_extension',
  CHANGE_TASK_FILE_ATTACHMENT_IDS = 'change_task_file_attachment_ids',
  READ_TASK_ID = 'read_task_id',
  READ_TASK_UUID = 'read_task_uuid',
  READ_TASK_NANO_ID = 'read_task_nano_id',
  READ_TASK_AMOUNT = 'read_task_amount',
  CHANGE_TASK_PREPAYMENT = 'change_task_prepayment',
  READ_TASK_PREPAYMENT = 'read_task_prepayment',
  READ_TASK_DEBT = 'read_task_debt',
  READ_TASK_TASK_ACCOUNT = 'read_task_task_account',
  READ_TASK_TAG_IDS = 'read_task_tag_ids',
  CHANGE_TASK_TAG_IDS = 'change_task_tag_ids',
  READ_TASK_STATUS = 'read_task_status',
  CHANGE_TASK_STATUS = 'change_task_status',
  READ_NEW_TASK_BUTTON = 'read_new_task_button',
  READ_NEW_TASK_DROPDOWN = 'read_new_task_dropdown',
  READ_TASK_FAVORITE_BUTTON = 'read_task_favorite_button',
  READ_TASK_VISIBLE_FOR_CLIENT = 'read_task_visible_for_client',
  READ_TASK_TOGGLE_NOTIFICATIONS_BUTTON = 'read_task_toggle_notifications_button',
  READ_TASK_MEMBER_TRACKED_TIME = 'read_task_member_tracked_time',
  READ_TASK_INVITE_USER_BUTTON = 'read_task_invite_user_button',
  READ_TASK_TEAM_MEMBER_MENU_DROPDOWN = 'read_task_team_member_menu_dropdown',
  READ_TASK_UPDATE_NAME_BUTTON = 'read_task_update_name_button',
  READ_TASK_UPDATE_DESCRIPTION_BUTTON = 'read_task_update_description_button',
  CREATE_TASK_BILL = 'create_task_bill',
  READ_TASK_ATTACHMENTS_MATERIALS_FOLDER = 'read_task_attachments_materials_folder',
  READ_TASK_ATTACHMENTS_SOURCE_FILES_FOLDER = 'read_task_attachments_source_files_folder',
  READ_TASK_SUPPORT_MARK = 'read_task_support_mark',
  READ_TASK_MARK = 'read_task_mark',
  READ_TASK_CLIENT_MENU_DROPDOWN = 'read_task_client_menu_dropdown',
  READ_TASK_IMPLEMENTATION_DATE = 'read_task_implementation_date',
  CREATE_TASK_DESCRIPTION = 'create_task_description',
  CREATE_TASK_AMOUNT = 'create_task_amount',
  CREATE_TASK_IMPLEMENTATION_DATE = 'create_task_implementation_date',
  CREATE_TASK_IMPLEMENTATION_TIME = 'create_task_implementation_time',
  CREATE_TASK_WIDTH = 'create_task_width',
  CREATE_TASK_HEIGHT = 'create_task_height',
  CREATE_TASK_DPI = 'create_task_dpi',
  CREATE_TASK_FILE_EXTENSION = 'create_task_file_extension',
  CREATE_TASK_FILE_ATTACHMENT_IDS = 'create_task_file_attachment_ids',
  READ_TASK_REVISION_IMPLEMENTATION_DATE_FILED = 'read_task_revision_implementation_date_field',
  READ_TASK_TRANSACTIONS_ID = 'read_task_transactions_id',
  READ_TASK_TRANSACTIONS_CREATED_AT = 'read_task_transactions_created_at',
  READ_TASK_TRANSACTIONS_AMOUNT = 'read_task_transactions_amount',
  READ_TASK_TRANSACTIONS_TOTAL_BALANCE = 'read_task_transactions_total_balance',
  READ_TASK_TRANSACTIONS_AUTHOR = 'read_task_transactions_author',
  READ_TASK_TRANSACTIONS_KIND = 'read_task_transactions_kind',
  READ_TASK_TRANSACTIONS_BALANCE_TYPE = 'read_task_transactions_balance_type',
  READ_TASK_TRANSACTIONS_BALANCE = 'read_task_transactions_balance',
  READ_TASK_TRANSACTIONS_PAYMENT_TYPE = 'read_task_transactions_payment_type',
  READ_TASK_TRANSACTIONS_TRANSACTION_TAGS = 'read_task_transactions_transaction_tags',
  READ_TASK_TRANSACTIONS_DESCRIPTION = 'read_task_transactions_description',
  READ_INVOICE_FORM_DESCRIPTION_FILED = 'read_invoice_form_description_field',
  READ_TASK_MESSAGES_TAB = 'read_task_messages_tab',
  READ_TASK_BRIEF_TAB = 'read_task_brief_tab',
  READ_TASK_ATTACHMENTS_TAB = 'read_task_attachments_tab',
  READ_TASK_RESULTS_TAB = 'read_task_results_tab',
  READ_TASK_TEAM_TAB = 'read_task_team_tab',
  READ_TASK_NOTIFICATIONS_TAB = 'read_task_notifications_tab',
  READ_TASK_TRANSACTIONS_TAB = 'read_task_transactions_tab',
  READ_TASK_ACTIVITY_TAB = 'read_task_activity_tab',
  READ_TASKS_TAG_IDS_FILTER = 'read_tasks_tag_ids_filter',
  READ_TASKS_SOURCE_FILES_FILTER = 'read_tasks_source_files_filter',
  READ_TASK_CREATE_BILL_BUTTON = 'read_task_create_bill_button',
  READ_DOWNLOAD_QA_TASK_RESULTS_BUTTON = 'read_download_qa_task_results_button',
  CHANGE_TASK_ATTACHMENTS_CHECKED = 'change_task_attachments_checked',
  READ_TASK_TRANSACTIONS_DIRECTION = 'read_task_transactions_direction',
  READ_TASKS_BURNING_FILTER = 'read_tasks_burning_filter',
  CHANGE_TASK_IMAGES_CHECKED = 'change_task_images_checked',
  CHANGE_TASK_FILES_CHECKED = 'change_task_files_checked',
  CHANGE_TASK_SOURCE_FILES_CHECKED = 'change_task_source_files_checked',
  CHANGE_TASK_MATERIALS_CHECKED = 'change_task_materials_checked',
  CHANGE_TASK_PRODUCTS_CHECKED = 'change_task_products_checked',
  CHANGE_TASK_LIFESTYLES_CHECKED = 'change_task_lifestyles_checked',
  READ_TASK_SEND_RESULTS_BUTTON = 'read_task_send_results_button',
  READ_TASKS_SEARCH_FILTERS_POPOVER = 'read_tasks_search_filters_popover',
  READ_TASK_RESULT_EDIT_BUTTON = 'read_task_result_edit_button',
  READ_TASK_BRIEF_REMOVE_SCOPE_OF_WORK_MESSAGE_BUTTON = 'read_task_brief_remove_scope_of_work_message_button',
  READ_TASK_SIDEBAR_FILE_ATTACHMENT_EDIT_BUTTON = 'read_task_sidebar_file_attachment_edit_button',
  READ_TASK_ATTACHMENTS_FILE_ATTACHMENT_EDIT_BUTTON = 'read_task_attachments_file_attachment_edit_button',
  READ_TASKS_BATCH_TRACK_TIME_BUTTON = 'read_tasks_batch_track_time_button',
  READ_TASKS_BATCH_UPDATE_TASKS_TAG_IDS_BUTTON = 'read_tasks_batch_update_tasks_tag_ids_button',
  READ_TASKS_BATCH_INVITE_USERS_TO_TASKS_BUTTON = 'read_tasks_batch_invite_users_to_tasks_button',
  READ_TASKS_BATCH_CREATE_TASKS_BILLS_BUTTON = 'read_tasks_batch_create_tasks_bills_button',
  READ_TASKS_BATCH_ACCEPT_TASKS_RESULTS_BUTTON = 'read_tasks_batch_accept_tasks_results_button',
  READ_TASKS_BATCH_UPDATE_TASKS_STATUS_BUTTON = 'read_tasks_batch_update_tasks_status_button',
  READ_INVOICE_FORM_TRANSACTION_TAGS_FIELD = 'read_invoice_form_transaction_tags_field',
  READ_TASKS_BATCH_COPY_TASKS_TO_PROJECT_BUTTON = 'read_tasks_batch_copy_tasks_to_project_button',
  READ_TASKS_BATCH_UPDATE_IMPLEMENTATION_DATES_BUTTON = 'read_tasks_batch_update_implementation_dates_button',
  READ_TASKS_BATCH_UPDATE_AMOUNTS_BUTTON = 'read_tasks_batch_update_amounts_button'
}

export const ignoredTasksFilters = ['name'];
