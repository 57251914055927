import {
  CreatedAt,
  DateType,
  DeviceNanoId,
  ID,
  MoneyType,
  StringFilter,
  UpdatedAt,
  UUID,
  NanoID,
  ModelBrand
} from '../../../types';

import {
  UserClient,
  UserFinanceRoleName,
  UserFullName,
  UserID,
  UserImageFile,
  UserImageUUID,
  UserNanoID,
  UserRoleName,
  UserUUID
} from '../../users/usersTypes';

import { TaskID, TaskName, TaskUUID } from '../../tasks/tasksTypes';

import { FileAttachmentID } from '../../fileAttachments/fileAttachmentsTypes';

import {
  MessageBillAmount,
  MessageBillID,
  MessageBillUUID,
  MessageColors,
  MessageCreatedAt,
  MessageFileAttachmentsFile,
  MessageFileAttachmentsUUID,
  MessageHours,
  MessageID,
  MessageMinutes,
  MessageSelectedProductsCreatedAt,
  MessageUpdatedAt,
  MessageUserClient,
  MessageUserImageFile,
  MessageUserUUID,
  MessageVisibleForClient
} from '../../messages/messagesTypes';

import { TagID, TagLocalizedName, TagUUID } from '../../tags/tagsTypes';

import {
  SelectedProductID,
  SelectedProductProductName,
  SelectedProductProductUUID,
  SelectedProductUUID
} from '../../selectedProducts/selectedProductsTypes';

import { SelectedLifestyleID } from '../../selectedLifestyles/selectedLifestylesTypes';

export type ProjectID = ID;
export type ProjectUUID = UUID;
export type ProjectNanoID = ModelBrand<NanoID, 'ProjectNanoID'>;
export type ProjectName = string;
export type ProjectDescription = string;
export type ProjectCreatedAt = CreatedAt;
export type ProjectUpdatedAt = UpdatedAt;
export type ProjectFavorite = boolean;
export type ProjectNotificationsEnabled = boolean;
export type ProjectBurning = boolean;
export type ProjectStatus = ProjectStatusOptions;
export type ProjectTasksClientStatus = ProjectStatusOptions;
export type ProjectEstimateCost = MoneyType;
export type ProjectTasksAmountsSum = MoneyType;
export type ProjectPrepaymentsSum = MoneyType;
export type ProjectDebt = MoneyType;
export type ProjectClientDebt = MoneyType;
export type ProjectProjectAccount = MoneyType;
export type ProjectSetDate = DateType;
export type ProjectImplementationDate = DateType;
export type ProjectRequirements = string[];
export type ProjectActiveTasksCount = number;
export type ProjectPerformedTasksCount = number;
export type ProjectCanceledTasksCount = number;
export type ProjectAcceptanceTasksCount = number;
export type ProjectDefinitionTasksCount = number;
export type ProjectCommission = number;
export type ProjectCreditCommission = number;
export type ProjectHostingCommission = number;
export type ProjectIncompleteSpecificationTasksCount = number;
export type ProjectQaTasksCount = number;
export type ProjectImplementationTasksCount = number;
export type ProjectTasksPrepaymentsSum = MoneyType;
export type ProjectTasksClientPrepaymentsSum = ModelBrand<
  MoneyType,
  'ProjectTasksClientPrepaymentsSum'
>;
export type ProjectDeviceNanoId = DeviceNanoId;
export type ProjectUnpaidBillsSum = number;

export type ProjectStatusOptions =
  | 'acceptance'
  | 'canceled'
  | 'definition'
  | 'implementation'
  | 'incomplete_specification'
  | 'performed'
  | 'qa';

export type ProjectUserID = UserID;
export type ProjectUserUUID = UserUUID;
export type ProjectUserNanoID = UserNanoID;
export type ProjectUserFullName = UserFullName;
export type ProjectUserImageUUID = UserImageUUID;
export type ProjectUserImageFile = UserImageFile;
export type ProjectUserClient = UserClient;
export type ProjectUserRoleName = UserRoleName;
export type ProjectUserFinanceRoleName = UserFinanceRoleName;

export type ProjectMembersID = UserID;
export type ProjectMembersUUID = UserUUID;
export type ProjectMembersNanoID = UserNanoID;
export type ProjectMembersFullName = UserFullName;
export type ProjectMembersRoleName = UserRoleName;
export type ProjectMembersFinanceRoleName = UserFinanceRoleName;
export type ProjectMembersImageUUID = UserImageUUID;
export type ProjectMembersImageFile = UserImageFile;
export type ProjectMembersClient = UserClient;

// export type ProjectMembersFinanceRoleName = UserFinanceRoleName; // Deprecated

export type ProjectTaskId = TaskID;
export type ProjectTaskUUID = TaskUUID;
export type ProjectTaskNames = TaskName[];
export type ProjectFileAttachmentIDs = FileAttachmentID[];

export type ProjectCreateProjectsBillsAccount = string;
export type ProjectCreateProjectsBillsAmount = number;
export type ProjectCreateProjectsBillsBody = string;
export type ProjectCreateProjectsBillsAllocableUser = string;
export type ProjectCreateProjectsBillsButtons = string;
export type ProjectCreateProjectsBillsCurrency = string;
export type ProjectCreateProjectsBillsTransactionTagId = ID;

export type ProjectMessageID = MessageID;
export type ProjectMessageVisibleForClient = MessageVisibleForClient;
export type ProjectMessageColors = MessageColors;
export type ProjectMessageHours = MessageHours;
export type ProjectMessageMinutes = MessageMinutes;
export type ProjectMessageCreatedAt = MessageCreatedAt;
export type ProjectMessageUpdatedAt = MessageUpdatedAt;

export type ProjectMessageUserUUID = MessageUserUUID;
export type ProjectMessageUserClient = MessageUserClient;
export type ProjectMessageUserImageFile = MessageUserImageFile;

export type ProjectMessageBillID = MessageBillID;
export type ProjectMessageBillUUID = MessageBillUUID;
export type ProjectMessageBillAmount = MessageBillAmount;

export type ProjectMessageFileAttachmentsUUID = MessageFileAttachmentsUUID;
export type ProjectMessageFileAttachmentsFile = MessageFileAttachmentsFile;

export type ProjectMessageProjectID = ProjectID;

export type ProjectMessageSelectedProductsCreatedAt =
  MessageSelectedProductsCreatedAt;

export type ProjectTagsID = TagID;
export type ProjectTagsUUID = TagUUID;
export type ProjectTagsLocalizedName = TagLocalizedName;

export type ProjectRankedNameFilter = StringFilter;
export type ProjectPerformerIdsFilter = ProjectMembersID[];

export type ProjectSelectedProductsID = SelectedProductID;
export type ProjectSelectedProductsUUID = SelectedProductUUID;
export type ProjectSelectedProductsProductName = SelectedProductProductName;
export type ProjectSelectedProductsProductUUID = SelectedProductProductUUID;

export type ProjectSelectedLifestylesID = SelectedLifestyleID;

export const enum ProjectFields {
  ID = 'id',
  UUID = 'uuid',
  NAME = 'name',
  BURNING = 'burning',
  DESCRIPTION = 'description',
  IMPLEMENTATION_DATE = 'implementationDate',
  ESTIMATE_COST = 'estimateCost',
  COMMISSION = 'commission',
  CREDIT_COMMISSION = 'creditCommission',
  HOSTING_COMMISSION = 'hostingCommission',
  CREATED_AT = 'createdAt',
  TASK_NAMES = 'taskNames',
  FILE_ATTACHMENT_IDS = 'fileAttachmentIds',
  TASKS = 'tasks'
}

export const enum ProjectTrackTimeField {
  BODY = 'body',
  HOURS = 'hours',
  MINUTES = 'minutes'
}

export enum ProjectBillFields {
  ID = 'id',
  UUID = 'uuid',
  AMOUNT = 'amount',
  CURRENCY = 'currency',
  BODY = 'body',
  ALLOCABLE_USER = 'allocableUser',
  ACCOUNT = 'account',
  BUTTONS = 'buttons',
  TRANSACTION_TAG_ID = 'transactionTagId',
  CREATED_AT = 'createdAt'
}
