import React from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../assets/icons/types';

import { useMenu } from '../../../../../app/hooks/useMenu/useMenu';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';

interface MenuPlainLinkProps {
  href: string;
  icon: IconsEnum;
  i18nText: string;
  active?: boolean;
}

function MenuPlainLink({ href, icon, i18nText, active }: MenuPlainLinkProps) {
  const { closeSecondaryMenu } = useMenu();

  return (
    <div className="-mx-4">
      <div
        className={cl(
          'flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500',
          active
            ? 'bg-opacity-25 hover:bg-opacity-30'
            : 'bg-opacity-0 hover:bg-opacity-20'
        )}
      >
        <div className="pl-2 pr-1">
          <Icon icon={icon} className="h-5 w-5 flex-shrink-0" />
        </div>
        <NextLinkHelper
          className="focus:outline-none flex-1 px-2 text-sm"
          onClick={closeSecondaryMenu}
          href={href}
        >
          <Translate id={i18nText} />
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>
      </div>
    </div>
  );
}

export default MenuPlainLink;
